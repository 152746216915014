/* @import url("https://fonts.cdnfonts.com/css/amazon-ember"); */
/* @import url("https://fonts.cdnfonts.com/css/pennsylvania"); "Pennsylvania",*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-font: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  /* --primary-font: "Amazon Ember", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif; */
  --dark: #232f3e;
  --theme: #ff9900;
}

body {
  margin: 0;
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  scrollbar-width: thin;
}

/* SCROLL BAR CSS */

/* Firefox */

/* Chrome, Edge, and Safari */
body::-webkit-scrollbar {
  width: 1px;
  height: 1rem;
  border-radius: 50%;
}

body::-webkit-scrollbar-track {
  background: #f7f5f4;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb {
  background-color: #1d1d1d;
  border-radius: 14px;
}
/* Firefox */
body,
.testimonial_content {
  scrollbar-width: thin;
}

/* TAILWIND LAYOUT CUSTOM CLASSES STARTS HERE */

@layer utilities {
  .app-logo-ratio {
    aspect-ratio: 3/2;
    object-fit: contain;
    mix-blend-mode: color-burn;
  }

  .app-transition-in-150 {
    @apply transition ease-in duration-150;
  }

  .app-content-anchor-hover a {
    @apply hover:!underline hover:!decoration-primary-hover;
  }

  .app-answer-content {
    @apply app-transition-in-150 app-content-anchor-hover;
  }

  /* DETAILS PAGE CSS CLASSES */

  .app-details-page-wrapper-grid {
    @apply grid md:grid-cols-[1fr_320px] gap-5;
  }

  .app-hover-primary-shadow {
    @apply hover:shadow-lg app-transition-in-150 hover:shadow-primary/30;
  }

  .app-hover-translated-primary-shadow {
    @apply hover:translate-y-[-2px] app-transition-in-150 hover:shadow-primary/30;
  }
}

/* TAILWIND LAYOUT CUSTOM CLASSES ENDS HERE */

.app-answer-content a {
  color: ;
}

/* Others SCROLL CSS */
/* Chrome, Edge, and Safari */
.scroll_style_thin::-webkit-scrollbar {
  width: 3px;
  height: 2px;
  border-radius: 50%;
}

.scroll_style_thin::-webkit-scrollbar-track {
  background: #dddd;
  border-radius: 5px;
}

.scroll_style_thin::-webkit-scrollbar-thumb {
  background-color: #232f3e;
  border-radius: 14px;
}

/* Signin dropdown  */

.before-arrow-right-unicode-single-element li::before {
  content: "\276F";
  font-size: 0.7rem;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 
  MEGA MENU CSS STARTS HERE
*/
.active-menu {
  opacity: 1;
  transform: translate(0, 0);
  z-index: 99;
  pointer-events: auto;
  transition: 0.5s;
}
.services-mega-menu {
  opacity: 0;
  transform: translate(0, 0);
  z-index: 99;
  pointer-events: none;
  transition: 0.5s;
}

/* Hero form css */
.select-arrow::after {
  content: "\276F";
  position: absolute;
  right: 10px;
  top: 20%;
  transform: rotate(90deg);
}
.phone-select-arrow::after {
  content: "\276F";
  position: absolute;
  left: 55px;
  top: 20%;
  transform: rotate(90deg);
}

/* free_features_list */

ul.free_features_list li:not(:last-child),
.border-b-not-last-child li:not(:last-child),
.border-b-not-last-child:not(:last-child) {
  border-bottom: 2px solid #f0eeed;
}

.border-b-gray-not-last-child:not(:last-child) {
  border-bottom: 1px solid #dadada;
}
/* Order page */
/* Order page form css */
.order-select-arrow::after {
  content: "\276F";
  position: absolute;
  right: 16px;
  top: 22%;
  transform: rotate(90deg);
}
/* Order page right css */
.order-page-offers-list li::before {
  content: "\2714";
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  height: 10px;
  width: 10px;
  background-color: rgb(2, 163, 2);
  margin-right: 4px;
  border-radius: 100%;
}

/* Service Page */
/*  adding arrow to all after elements */
.after-arrow-right-unicode-single-element::after,
.after-arrow-right-unicode:not(:last-child)::after {
  content: "\276F";
  margin-left: 3px;
}

.top_section_whatsapp_bg::after {
  content: "";
  height: 16px;
  width: 18px;
  background: #f0eeed;
  position: absolute;
  left: 2px;
  top: 4px;
  border-radius: 100%;
  z-index: -1;
}

/*  FOOTERS css*/
.links-after-pipe:not(:last-child):after {
  content: "|";
  margin-left: 10px;
}

.footer_section_whatsapp_bg::after {
  content: "";
  height: 19px;
  width: 21px;
  background: #f0eeed;
  position: absolute;
  left: 2px;
  top: 5px;
  border-radius: 100%;
  z-index: -1;
}

/* Register page css starts here */
/* .dark-glass-effect::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
} */
/* Register page css ends here */

/* primary css starts here */
.floating-border-l::before {
  content: "";
  position: absolute;
  width: 50%;
  top: 50%;
  left: -25px;
  border: 1px solid rgba(0, 0, 0, 0.303);
}
.floating-border-r::after {
  content: "";
  position: absolute;
  width: 50%;
  top: 50%;
  right: -25px;
  border: 1px solid rgba(0, 0, 0, 0.303);
}
.shadow_type_one {
  box-shadow: 3px 2px 17px -3px rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 3px 2px 17px -3px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 3px 2px 17px -3px rgba(0, 0, 0, 0.41);
}
/* react date picker css starts here */
.react-datepicker__triangle {
  display: none !important;
}
.react-datepicker-popper {
  /* left: -265% !important; */
  left: -41% !important;
  top: 15% !important;
}
/* react date picker css ends here */
/* primary css ends here */

/***************************************


ZENHUB CHAT BOX CSS STARTS HERE



***************************************/

/* .gHHEeh{
  display: block !important;
  background-color: #052042 !important;
  border-radius: 0.25rem !important;
} */

/***************************************


ZENHUB CHAT BOX CSS ENDS HERE



***************************************/

/***************************************


ALL ANIMATIONS STARTS HERE



***************************************/

.blink-effect {
  -webkit-animation: beat 400ms cubic-bezier(0.39, 0.575, 0.565, 1) infinite
    alternate both;
  animation: beat 400ms cubic-bezier(0.39, 0.575, 0.565, 1) infinite alternate
    both;
}
@-webkit-keyframes beat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes beat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

/***************************************


ALL ANIMATIONS ENDS HERE



***************************************/
